import {array, date, record, string} from '@cindedi/validation';
import {cindediGid} from '@cindedi/validation/rules/cindediGid';
import {optional} from '@cindedi/validation/rules/optional';
import {PublicUserDto} from '~/domains/account/dtos/PublicUserDto';
import {PublicCoverDto} from '~/domains/file-system/dtos/PublicCoverDto';
import {PublicFileDto} from '~/domains/file-system/dtos/PublicFileDto';

export const PublicPostDto = record<Blog.PublicPostDto>({
  id: cindediGid({domain: 'Blog', entity: 'Post'}),
  createdAt: date(),
  updatedAt: date(),
  title: string(),
  headline: optional(string()),
  content: string(),
  slug: string(),
  textContent: string(),
  publishedAt: optional(date()),
  cover: optional(PublicCoverDto),
  files: array(PublicFileDto),
  user: PublicUserDto,
});
