import {Link} from '@remix-run/react';
import {formatDate} from '../../utilities/formatDate';
import {truncate} from '@cindedi/utilities/truncate';

export interface PostListItemProps {
  post: Blog.PublicPostDto;
}

export function PostListItem({post}: PostListItemProps) {
  return (
    <li className="hover:bg-shade-3 bg-shade-2 rounded">
      <Link to={`/noticias/${post.slug}`}>
        <div className="flex">
          {post.cover && (
            <div
              role="img"
              className="w-20 h-20 bg-shade-4 rounded bg-no-repeat bg-center bg-cover m-2"
              style={{backgroundImage: `url(${post.cover.url})`}}
              aria-label={post.title}
            />
          )}
          <div className="px-2 py-2 flex flex-col w-full">
            <div className="flex gap-2 text-shade-11 text-sm items-center mb-1.5">
              <span>{formatDate(post.publishedAt!)}</span>•<span>{post.user.name}</span>
            </div>
            <span className="font-semibold text-primary-12">{post.title}</span>
            <span className="text-shade-11">
              {post.headline ? (
                <span>{truncate(post.headline, 200)}</span>
              ) : (
                truncate(post.textContent, 200)
              )}
            </span>
          </div>
        </div>
      </Link>
    </li>
  );
}
