import {useComputed} from '@cindedi/signals/hooks/useComputed';
import type {Jsonify} from '@cindedi/utilities/types';
import {PublicPostDto} from '../../dtos/PublicPostDto';
import {PostListItem} from '../PostListItem';

export interface PostListProps {
  posts: Jsonify<Blog.PublicPostDto[]>;
}

export function PostList({posts}: PostListProps) {
  const list = useComputed(
    () =>
      posts
        .map((data) => {
          try {
            const dto = PublicPostDto(data);
            if (dto.error) throw dto.error;
            return dto.valid ? dto.value : null;
          } catch (error) {
            console.log(data, JSON.stringify(error, null, 2));
            return null;
          }
        })
        .filter(Boolean) as Blog.PublicPostDto[],
  );

  return (
    <ul role="list" className="flex flex-col gap-2">
      {list.value.map((post) => (
        <PostListItem key={post.id} post={post} />
      ))}
    </ul>
  );
}
